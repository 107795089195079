<template>
  <fragment v-show="show">
    <q-tr class="new-information">
      <q-td :style="{ width: dataLabelWidth }">
        <Autocomplete
          :edit-label-title="getNewInfo.dataLabel"
          @setEditLabelTitle="setEditLabelTitle"
          placeholder="Intitulé de l'information"
        />
      </q-td>
      <q-td :style="{ width: dataValueWidth }">
        <q-input
          square
          outlined
          bottom-slots
          v-model="getNewInfo.dataValue"
          placeholder="Contenu de l'information"
          type="text"
          lazy-rules
          :rules="[val => (val && val.length > 0) || 'Veuillez entrer une information']"
        />
      </q-td>
      <q-td :style="{ width: sourceDocumentTypeWidth }">
        <q-input
          square
          outlined
          bottom-slots
          v-model="getNewInfo.sourceDocumentType"
          placeholder="Source de l'information"
          type="text"
        />
      </q-td>
      <q-td :style="{ width: sourceDocumentDateWidth }">
        <q-input
          filled
          v-model="getNewInfo.sourceDocumentDate"
          mask="##/##/####"
          placeholder="Date"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="getNewInfo.sourceDocumentDate"
                  mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Fermer" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-td>
    </q-tr>
    <q-tr></q-tr>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from '@/components/project/Autocomplete'

export default {
  name: 'NewInfo',
  components: { Autocomplete },
  computed: {
    ...mapGetters('projects', ['getNewInfo']),
    ...mapGetters('messages', ['getIsOpen'])
  },
  data() {
    return {
      show: false,
      dataLabelWidth: '',
      dataValueWidth: '',
      sourceDocumentTypeWidth: '',
      sourceDocumentDateWidth: '',
      maxTimer: 40,
      timer: 0
    }
  },
  methods: {
    setEditLabelTitle(title) {
      this.getNewInfo.dataLabel = title
      this.getNewInfo.entityId = 'custom'
    },
    setWidth() {
      let timer = setInterval(() => {
        const dataLabel = document.querySelector('.td-data-value')
        const dataValue = document.querySelector('.td-info-value')
        const sourceDocumentType = document.querySelector('.td-info-type')
        const sourceDocumentDate = document.querySelector('.td-update')

        if (dataLabel) {
          clearTimeout(timer)

          this.dataLabelWidth = dataLabel.clientWidth + 'px'
          this.dataValueWidth = dataValue.clientWidth + 'px'
          this.sourceDocumentTypeWidth = sourceDocumentType.clientWidth + 'px'
          this.sourceDocumentDateWidth = sourceDocumentDate.clientWidth + 'px'

          this.show = true
        }

        if (this.timer >= this.maxTimer) {
          clearTimeout(timer)

          const title = document.querySelector(
            '.q-table thead tr th:nth-child(1)'
          )
          const info = document.querySelector(
            '.q-table thead tr th:nth-child(2)'
          )
          const type = document.querySelector(
            '.q-table thead tr th:nth-child(3)'
          )
          const date = document.querySelector(
            '.q-table thead tr th:nth-child(4)'
          )

          this.dataLabelWidth = title.clientWidth + 'px'
          this.dataValueWidth = info.clientWidth + 'px'
          this.sourceDocumentTypeWidth = type.clientWidth + 'px'
          this.sourceDocumentDateWidth = date.clientWidth + 'px'

          this.show = true
        }

        this.timer += 1
      }, 10)
    }
  },
  created() {
    this.setWidth()
    window.addEventListener('resize', this.setWidth)
  },
  watch: {
    getIsOpen: function() {
      if (this.show) setTimeout(() => this.setWidth(), 290)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setWidth)
  }
}
</script>
